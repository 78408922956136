import React from 'react';
import StartFirebase from '../firebaseConfig/firebase';
import CustomMarker from '../Marker';
import {ref,onValue} from 'firebase/database'
import CustomMarkerMainCity from '../Marker/CustomMarkerMainCity';
import { serverLink } from '../Variable';
const db = StartFirebase();
export class Data extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: this.props.items,
          data:null,
          radius:this.props.radius,
          counter:0,
          setArray:null,
          city:this.props.city,
          question:this.props.question,
          mainCity:false,
          event:"",
          image_url:""
        }
      }

      async componentDidMount(){
        var city = (this.state.city).toLowerCase()
        if(city === "london" || city === "paris" || city === "new york" || city === "berlin" ||  city === "roma"){
          this.setState({
            mainCity:true
          })
          this.callMyData();
        
        }else{
          const timer = ms => new Promise(res => setTimeout(res, ms))
          for(var i = 0 ; i <= 10 ; i++){
            if(i===0){
              this.callDataAI(true);
              await timer(15000);
            }else{
              this.callDataAI();
              await timer(15000);
            }
          }
        }
      }
    
    
    async callDataAI(fristTime){
      var prompt;
      if(this.state.question){
        if(fristTime){
          prompt = this.state.question + ' Format for answer JSON{"event":"???","description":"???","date":"mm/dd/yyyy" ,"type":"",longitude:"???","latitude":"???"} . Event should be location related ' +this.state.city +'. Return everything in json format in one line.';
        }else{
          prompt = this.state.question + ' Format for answer JSON{"event":"???","description":"???","date":"mm/dd/yyyy" ,"type":"",longitude:"???","latitude":"???"} . Event should be location related ' +this.state.city +'. Return everything in json format in one line. Event not to be: '+this.state.event+'';
        }
      }else{
        if(fristTime){
          prompt='Give me interesting historical event for '+ this.state.city+' in the following form {"event":"???","description":"???","date":"mm/dd/yyyy" ,"type":"",longitude:"???","latitude":"???"} It is mandatory to put quotation marks on the keys in json.Send longitude and latitude as numbers without additional tags.Send longitude and latitude as numbers without additional tags.'
        }else{
          prompt='Give me another historical interesting event (not to be: '+this.state.event+') for '+ this.state.city+' in the following form {"event":"???","description":"???","date":"mm/dd/yyyy" ,"type":"",longitude:"???","latitude":"???"}.Send longitude and latitude as numbers without additional tags.'
        }
      }
      //serverLink
      const respons = await fetch(serverLink+"openAi",{
            method:'POST',
            headers:{
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // prompt: "Give me the event, description, date in mm/dd/yyyy format, event type for the 5 most interesting historical events within 1 kilometer of the following location: "+this.state.city+". For each historical event, return me the longitude and the longitude where that event was located on the map. Return everything in json format in one line, ordered by date. I am only interested in events that happened within 1 km of "+this.state.city+". Please check if each event is related to the location I sent you.Please do not repeat the events!" 
                //prompt:"Give me the Event, Description, Date in mm/dd/yyyy format, Event Type for the 5 most interesting historical events within 1 kilometers of the following location: "+this.state.city+". For each historical event, return to me the Longitude and Longitude where that event was located on the map. Return it all to me in json format in one line, ordered by date."
              //prompt:"Give me Event, Description, Date in mm/dd/yyyy format, Event type (birth, death, wars up to 1700, wars from 1701 to 1900, wars from 1901 to 1920, wars from 1921 to 1950 and wars from 1950 to 2021, weather problems) for 5 historical events related to wars, murders, deaths, births, weather problems within "+localStorage.getItem("range")+" meters of the following location: "+this.state.citi+". For each historical event, return me the longitude and the longitude where that event was located on the map. Return everything in json format in one line and start each key in json with a lowercase letter , sorted by date. I'm only interested in events that happened within 1 km of "+this.state.city+". Check that each event is linked to the location I sent you. Do not repeat events!"
              //prompt:"Give me the event, description, date in mm/dd/yyyy format and type (best description of that event in 2-3 words ) for 1 historical events within "+localStorage.getItem("range")+" meters of the following location: " + this.state.city+". For each historical event, return me the longitude and latitude where that event was located on the map. Return everything in json format in one line and start each key in the json with a lowercase letter, sorted by date. I'm only interested in events that happened within 1000 meters of "+this.state.city+". Check that each event is linked to the location I sent you. Do not repeat events!"
              prompt:prompt
            })
          })

          if(respons.ok){
              const data = await respons.json();
              const parsedData = data.bot.trim();
              const respons2 = await fetch(serverLink +"createImages",{
                method:"POST",
                headers:{
                'Content-Type': 'application/json',
                },
                body:JSON.stringify({
                  prompt:JSON.parse(parsedData).event
                })
              })
              if(respons2.ok){
                const data2 = await respons2.json();
                const parsedData2 = data2.bot.data[0]['url'];
                if(fristTime){
                  this.setState({
                    data: JSON.parse(parsedData),
                    event:JSON.parse(parsedData).event,
                    image_url:parsedData2
                  });
                  }else{
                    this.setState({
                      data: JSON.parse(parsedData),
                      event:this.state.event+","+JSON.parse(parsedData).event,
                      image_url:parsedData2
                    })
                  }
              }
          }else{
              alert("error")
          }
        
    }



    async callMyData(){
      const dbRef = ref(db,'/2/data');
        onValue(dbRef,(snapshot)=>{
            var records=[]
            snapshot.forEach(childSnapshot=>{
                let keyName=childSnapshot.key;
                let data=childSnapshot.val();
                if(this.getDistance([data.long_marker,data.lat],[this.state.items[0].lon,this.state.items[0].lat])<=this.state.radius && records.length<5){
                    records.push({"key":keyName,"data":data})
                }
            })

            records.forEach((element) => {
                if(element.data.bc_ad==="BC"){
                    element.data.complateYear = element.data.year * (-1)
                }else{
                    element.data.complateYear = element.data.year 
                }
            })

            records.sort(function(a,b){
                return parseFloat(a.data.complateYear) - parseFloat(b.data.complateYear);
            })

            this.setState({
                data: records
            });
          })
    }
  


    getDistance(origin, destination) {
        var lon1 = this.toRadian(origin[1]),
            lat1 = this.toRadian(origin[0]),
            lon2 = this.toRadian(destination[1]),
            lat2 = this.toRadian(destination[0]);
    
        var deltaLat = lat2 - lat1;
        var deltaLon = lon2 - lon1;
    
        var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
        var c = 2 * Math.asin(Math.sqrt(a));
        var EARTH_RADIUS = 6371;
        return c * EARTH_RADIUS * 1000;
    }
    
    toRadian(degree){
        return degree*Math.PI/180
    }
    sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }

    render() {

        if (this.state.data == null) {
            return 'Loading...';
        }else{
          if(this.state.mainCity) {
            return (
              <CustomMarkerMainCity things={this.state.data} image_url={this.state.image_url}></CustomMarkerMainCity>
            );
          }else{
            return (
              <CustomMarker things={this.state.data} image_url={this.state.image_url}></CustomMarker>
            );
          }
            
        }
    }
}
