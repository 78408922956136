import ReactDOM from "react-dom";
import {Circle} from "react-leaflet";

import React, { useState } from 'react';
import PropTypes from 'prop-types';


function MarkerRadius(props) {
    return (
        <div>
            <Circle center={[props.center[0],props.center[1]]} radius={props.radius} />
        </div>
    );
}

export default MarkerRadius;